<template>
  <div class="d-flex align-center mt-2 justify-space-between">
    <div class="d-flex align-center">
      <v-icon class="mb-1">mdi-circle-small</v-icon>
      비공개 프로젝트로 설정
    </div>
    <v-switch dense inset hide-details class="mt-0 pt-0 mr-0" v-model="value" />
  </div>
</template>

<style lang="scss" scoped>
.v-input--switch::v-deep {
  .v-input__slot {
    max-width: 42px !important;
    .v-input--selection-controls__input {
      margin-right: 0px !important;
      input {
        max-width: 42px !important;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    privateStatus: {
      type: Number,
      default: 0
    },
    exposureStatus: {
      type: Number,
      default: 1
    }
  },
  computed: {
    value: {
      get() {
        return this.privateStatus;
      },
      set(value) {
        value = value ? 1 : 0;

        this.$emit("update:privateStatus", value);
        if (!value) this.$emit("update:exposureStatus", 1);
      }
    }
  }
};
</script>
